import React from 'react';
import './Landingcours.css'
import landing1 from '../design/Landing1.jpg'
import landing2 from '../design/Landing2.jpg'
import video from "../video/v1.mp4"
import graduation from "../design/Graduation.jpeg"
function Landingcours() {

    return (
      <div className="Landingcours">
            <div className="landing1">
                <img src={landing1} alt="landing1" className='landing1IMG' />
            </div>
            <div className="landing1">
                <img src={landing2} alt="landing1" className='landing1IMG' />
                <div className="placevideo">
                  <div className="placevideoCenter">
                    <div className="BtnTopVideo">
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLScvD9YykZnA14ewf6oyrGEIMX0Z6D2EP37NaEvsOUNqXg2rKw/viewform" className='BtnTopVideoCenter'></a>
                    </div>
                    <video src={video} className="videoSize" loop autoPlay muted playsInline controls></video>
                    <div className="BtnBottomVideo">
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLScvD9YykZnA14ewf6oyrGEIMX0Z6D2EP37NaEvsOUNqXg2rKw/viewform" className='BtnBottomVideoCenter'></a>
                    </div>
                    <div className="PlaceImageFin">
                      <img src={graduation} alt="img" className="PlaceImageFinImg" />
                    </div>
                  </div>
                </div>
            </div>
      </div>
    );
  }
  
  export default Landingcours;
  